/**
 * Random phrases
 */

const phrases: string[] = [
'Calculating river sinuosity',
'Righting the native crabs',
'Scrubbing off didymo',
'Adjusting the tidal cycle',
'Migrating knickpoints',
'Accounting for orbital eccentricity',
'Asking Milenchovitch to adjust the cycles',
'Reversing the magnetic poles'
]

/**
 * Return a random phrase
 */
export function randomPhrase(): string {
    return phrases[Math.floor(Math.random() * phrases.length)]
}
