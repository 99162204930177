"use client";

import * as React from "react";
import { Alert } from "reactstrap";
import { loginUrl, registerUrl } from "../../shared/auth";
interface Props {
  message?: string;
}
export const LoggedOutAlert: React.FC<Props> = ({
  message
}) => <Alert color="warning" data-sentry-element="Alert" data-sentry-component="LoggedOutAlert" data-sentry-source-file="LoggedOutAlert.tsx">
    <h4 className="alert-heading">Currently logged out</h4>
    <p>
      You are currently logged out. Please{" "}
      <a href={loginUrl()} className="alert-link">
        login
      </a>{" "}
      or{" "}
      <a href={registerUrl()} className="alert-link">
        register
      </a>{" "}
      {message ? message : "to create a trip"}
    </p>
  </Alert>;