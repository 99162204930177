/**
 * GraphQL schema and types for querying missions
 */
import { gql } from "@apollo/client"

export interface Mission {
  id: number
  title: string
  slug: string
  blurb: string
  group: boolean
  individual: boolean
  listing?: {
    rendition: {
      url: string
    }
  }
}

export interface QueryData {
  missions: Mission[]
}

export enum Status {
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  COMING_SOON = "COMING_SOON",
}

export interface QueryVariables {
  /** Mission avalibility status (for ones that are publicly shown but possibly not started yet) */
  status?: Status
  /** Filter out individual focused missions */
  notClassroom?: boolean
  /** Filter out group focused missions */
  notIndividuals?: boolean
  /** Search text */
  search?: string
}

export const ALL_MISSION_QUERY = gql`
  query missions {
    missions {
      id
      title
      slug
      blurb
      group
      individual
      listing {
        rendition(fill: "300x300") {
          url
        }
      }
    }
  }
`

export const FILTER_QUERY = gql`
  query missions(
    $status: String
    $notIndividuals: Boolean
    $notClassroom: Boolean
    $search: String
  ) {
    missions(
      status: $status
      notIndividuals: $notIndividuals
      notClassroom: $notClassroom
      search: $search
    ) {
      id
      title
      slug
      blurb
      group
      individual
      listing {
        rendition(fill: "300x300") {
          url
        }
      }
    }
  }
`
