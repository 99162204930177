import { useQuery } from "@apollo/client";
import React from "react";
import { ErrorAlert } from "components/Error";
import { LoadingAlert } from "components/Loading";
import { ALL_MISSION_QUERY, QueryData } from "./schema";
interface Props {
  children(props: QueryData): JSX.Element;
}
export const AllMissionsQuery: React.FC<Props> = ({
  children
}) => {
  const {
    loading,
    error,
    data
  } = useQuery<QueryData>(ALL_MISSION_QUERY);
  if (loading) {
    return <LoadingAlert />;
  }
  if (error) {
    return <ErrorAlert error={error} title="Unable to load missions" message="Please try again later" />;
  }
  return children(data!);
};