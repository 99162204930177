export const parseBooleanParams = (searchParams: any) => {
  const parsedParams = Object.keys(searchParams).reduce((acc: any, key) => {
    if (searchParams[key] === "true") {
      acc[key] = true as boolean;
    }
    if (searchParams[key] === "false") {
      acc[key] = false as boolean;
    } else {
      acc[key] = searchParams[key];
    }
    return acc;
  }, {});
  return parsedParams;
};