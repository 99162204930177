"use client";

import { Field, Form, Formik } from "formik";
import { usePathname, useRouter } from "next/navigation";
import React from "react";
import { Alert, Button } from "reactstrap";
import { loginUrl } from "shared/auth";
export const LoggedOutPassphraseAlert: React.FC = () => {
  const router = useRouter();
  const pathname = usePathname();
  return <Alert color="warning" data-sentry-element="Alert" data-sentry-component="LoggedOutPassphraseAlert" data-sentry-source-file="LoggedOutPassphraseAlert.tsx">
      <h4 className="alert-heading">Authorization Required</h4>
      <p>
        This datasheet is not available for editing. You can only edit datasheets that you’ve
        submitted, and you must be logged in or use a passphrase to access. Additionally datasheets
        that have been locked or validated are unable to be edited.
      </p>
      <p>
        Please{" "}
        <a href={loginUrl()} className="alert-link">
          login
        </a>{" "}
        to edit datasheets you’ve submitted.
      </p>
      <hr />
      <p>If you a have a passphrase for this datasheet, you can enter that in the box below.</p>
      <Formik initialValues={{
      passphrase: ""
    }} onSubmit={(values, actions) => {
      console.log("Redirecting");
      router.push(`${window.location.pathname.split("?")[0]}?passphrase=${values.passphrase}`);
    }} data-sentry-element="Formik" data-sentry-source-file="LoggedOutPassphraseAlert.tsx">
        {({
        values,
        isSubmitting
      }) => <React.Fragment>
            {isSubmitting === true ? null : <Form>
                <Field type="text" name="passphrase" />
                <Button style={{
            marginLeft: "1rem"
          }} type="submit" disabled={isSubmitting} color="primary">
                  Edit with passphrase
                </Button>
              </Form>}
          </React.Fragment>}
      </Formik>
    </Alert>;
};