import React from "react";
import { MissionBadgeProps } from "Core/Project/Single/schema";
import { GroupSelected } from "shared/icons/icon-group-selected";
import { IndividualSelected } from "shared/icons/icon-individual-selected";
const badgeFormatStyle = {
  display: "flex",
  flexDirection: "column" as "column",
  alignItems: "center",
  justifyContent: "center"
};
export const MissionBadge: React.FC<MissionBadgeProps> = ({
  individual,
  group
}: MissionBadgeProps) => <div className="mission-badge" data-sentry-component="MissionBadge" data-sentry-source-file="index.tsx">
    {group ? <div style={badgeFormatStyle}>
        <GroupSelected />
        Group
      </div> : null}

    {group && individual ? <div style={{
    ...badgeFormatStyle
  }}>
        <b>&</b>
      </div> : null}

    {individual ? <div style={badgeFormatStyle}>
        <IndividualSelected />
        Individual
      </div> : null}
  </div>;