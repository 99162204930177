"use client";

import * as Sentry from "@sentry/browser";
import * as React from "react";
import { Alert } from "reactstrap";
interface Props {
  title: string;
  message: string;
  level?: string;
  error?: Error;
}
export const ErrorAlert: React.FC<Props> = ({
  message,
  level,
  title,
  error
}) => {
  if (error) {
    console.error(error);
    Sentry.captureException(error);
  }
  return <Alert color={level ? level : "warning"} data-sentry-element="Alert" data-sentry-component="ErrorAlert" data-sentry-source-file="Alert.tsx">
      <h4>Error: {title}</h4>
      <hr />
      <p>{message}</p>
    </Alert>;
};