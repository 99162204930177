/**
 * Query a filtered list of missions
 */
import { useQuery } from "@apollo/client";
import React from "react";
import { ErrorAlert } from "components/Error";
import { LoadingAlert } from "components/Loading";
import { parseBooleanParams } from "shared/urlParams/util";
import { FILTER_QUERY, QueryData, QueryVariables } from "./schema";
interface Props extends QueryVariables {
  children(props: QueryData): JSX.Element;
}
/**
 * Query a filtered list of missions
 *
 * @param children Element that recieves filtered list of missions
 * @param filters Filters to apply to apply to the missions
 */
export const MissionFilterQuery: React.FC<Props> = ({
  children,
  ...filters
}: Props) => {
  const cleanedBooleans = parseBooleanParams({
    ...filters
  });
  const cleanedFilters = cleanFilters(cleanedBooleans);
  const {
    loading,
    error,
    data
  } = useQuery<QueryData, QueryVariables>(FILTER_QUERY, {
    variables: cleanedFilters
  });
  if (loading) {
    return <LoadingAlert />;
  }
  if (error) {
    return <ErrorAlert error={error} title="Unable to load missions" message="Please try again later" />;
  }
  return children(data!);
};
const cleanFilters = (filters: any) => {
  return Object.keys(filters).reduce((acc: any, f) => {
    if (f === "forGroups") {
      acc["notClassroom"] = !filters["forGroups"];
    } else if (f === "forIndividuals") {
      acc["notIndividuals"] = !filters["forIndividuals"];
    } else acc[f] = filters[f];
    return acc;
  }, {});
};