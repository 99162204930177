/**
 * A filtered group of missions
 */
import * as React from "react";
import { MissionList } from "./component";
import { MissionFilterQuery } from "./filterQuery";
import { QueryData, QueryVariables } from "./schema";

/**
 * A filtered group of missions
 *
 * @params filters Variables to filter the selection of missions with.
 */
export const FilteredMissionList: React.FC<QueryVariables> = (filters: QueryVariables) => <MissionFilterQuery {...filters} data-sentry-element="MissionFilterQuery" data-sentry-component="FilteredMissionList" data-sentry-source-file="FilteredList.tsx">
    {(data: QueryData) => <MissionList {...data} />}
  </MissionFilterQuery>;