/**
 * Component to display a list of missions
 */
import Link from "next/link";
import * as React from "react";
import { Col, Row } from "reactstrap";
import { MissionBadge } from "Core/Project/Badge";
import { paths } from "shared/constants";
import { urlPartReplacer } from "shared/urlParams";
import { QueryData } from "./schema";

/**
 * List/grid display of missions
 *
 * @param missions Array of missions
 */
export const MissionList: React.FC<QueryData> = ({
  missions
}: QueryData) => <Row className="mission-list" data-sentry-element="Row" data-sentry-component="MissionList" data-sentry-source-file="component.tsx">
    {missions.map(mission => <Col sm={6} md={4} lg={3} key={mission.slug} className="mission-col">
        <Link href={urlPartReplacer(paths.missions.mission, ":slug", mission.slug)} style={{
      color: "black"
    }}>
          <div className="overlapping-badge">
            {mission.listing ? <img src={mission.listing.rendition.url} style={{
          maxWidth: "100%"
        }} alt={mission.title} /> : null}
            <MissionBadge group={mission.group} individual={mission.individual} />
          </div>
          <h5>{mission.title}</h5>
        </Link>
        &mdash;
        <p>{mission.blurb}</p>
      </Col>)}
    {missions.length === 0 ? <Col>There are currently no missions avaliable.</Col> : null}
  </Row>;