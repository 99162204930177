"use client";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Alert } from "reactstrap";
import { randomPhrase } from "./phrases";
interface Props {
  message?: string;
}
export const LoadingAlert: React.FC<Props> = ({
  message
}) => {
  const phrase = randomPhrase();
  return <Alert color="info" data-sentry-element="Alert" data-sentry-component="LoadingAlert" data-sentry-source-file="Alert.tsx">
      <h4>
        <FontAwesomeIcon icon={faSpinner} spin={true} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="Alert.tsx" /> Loading...
      </h4>
      {message ? <h6>{message}</h6> : null}
      <hr />
      <p suppressHydrationWarning={true}>{phrase}</p>
    </Alert>;
};